export const config = {
   serverRoute: "https://test-prueba-production.up.railway.app"
   //serverRoute: "http://localhost:3001"
}

export const SocketRoute = {
   serverRoute: "https://railway.grupo-hoteles.com"
   //serverRoute: "http://localhost:3001"
}

export const CLoubedsRoute ={
   serverRoute: "https://railway.grupo-hoteles.com"
   //serverRoute:"http://localhost:3001"
}


export const Environment ={
  propertyID:"315191",
  Token:"cbat_SQeHkJAHRRdwp4bEtUx8muk97f34C5su"
}
